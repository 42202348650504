<template>
  <div>
    <div class="main-Pc-wholeImg">
      <div class="nav-box">
        <div class="nav">
          <img class="Pc-back" :src="Pc_back" alt="" @click="pageHandle('/home')">
          <img class="Pc-select" :src="Pc_select" alt="" @click="select()">
          <ul class="nav-language" v-if="flag">
            <li><a href="/tcapp-letsgo-JA">日本語</a></li>
            <li><a href="/tcapp-letsgo-SC">简体中文</a></li>
            <li><a href="/tcapp-letsgo-TC">繁体中文</a></li>
            <li><a href="/tcapp-letsgo-ENG">English</a></li>
            <li><a href="/tcapp-letsgo-KR">한국어</a></li>
          </ul>
        </div>
      </div>
      <img class="Pc-wholeImg" :src="contentList" width="100%" lazy @click="background()">
      <div class="Pc-link-box">
        <a class="Pc-apple-link" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
          <img :src="Pc_apple" alt="">
        </a>
        <a v-if="language != 'zh'" class="Pc-google-link" href=" https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
          <img :src="Pc_google" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang"
export default {
  data() {
    return {
      contentList: require('@/assets/images/customer/CH.jpg'),
      Pc_apple: require("@/assets/images/customer/apple.png"),
      Pc_google: require("@/assets/images/customer/GOOGLE.png"),
      Pc_back: require("@/assets/images/customer/PC-home.png"),
      Pc_select: require("@/assets/images/customer/PC_line_CH.png"),
      flag: false,
    }
  },
  computed: {
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    select() {
      this.flag = !this.flag
    },
    background() {
      this.flag = false
    }
  }
}
</script>

<style lang='scss' scoped>
body {
  width: 100%;
  height: 100%;
}

.app-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
}

.main-Pc-wholeImg {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.Pc-back {
  width: 30px;
  float: left;
  left: 3px;
  top: 2px;
}
.Pc-selectBox {
  float: right;
}
.Pc-select {
  height: 22px;
  float: right;
  cursor: pointer;
}
.nav-box {
  width: 100%;
  height: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.nav {
  width: 1000px;
  display: block;
}
.nav-language {
  position: absolute;
  right: 24%;
  top: 40px;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #0000001a;
}
.nav-language li {
  list-style: none;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  border-bottom: 1px solid #0000001a;
}
.nav-language li a {
  color: #606266;
  display: block;
  width: 96px;
  text-align: center;
}
.nav-language li a:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.nav-language li:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.rr{
  position: relative;
    height: 20px;
    width: 12px;
    top: -12px;
    left: 7px;
    background: #0c0c0c;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
@media screen and (max-width: 768px) {
  .Pc-back {
    width: 30px;
  }
  .Pc-select {
    margin-top: 4px;
  }
  .Pc-link-box {
    position: absolute;
    // left: 65%;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .Pc-apple-link img {
    height: 65px;
    margin-right: 1rem;
  }
  .Pc-google-link img {
    height: 65px;
  }
  .nav-box {
    width: 100%;
    position: absolute;
    height: 44px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    width: 652px;
    display: block;
    margin: 0 auto;
  }
  .Pc-wholeImg {
    margin-top: 40px !important;
  }
  .nav-language {
    position: absolute;
    right: 8%;
    top: 40px;
    margin: 5px 0;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #0000001a;
  }
}
@media screen and (min-width: 769px) and(max-width: 1024px) {
  .Pc-back {
    width: 30px;
  }
  .Pc-select {
    margin-top: 4px;
  }
  .Pc-link-box {
    position: absolute;
    // left: 58%;
    bottom: 3.3rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .Pc-apple-link img {
    height: 65px;
    margin-right: 1rem;
  }
  .Pc-google-link img {
    height: 65px;
  }
  .nav-box {
    width: 100%;
    position: absolute;
    height: 44px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    width: 600px;
    display: block;
    margin: 0 auto;
  }
  .nav-language {
    position: absolute;
    right: 21%;
    top: 40px;
    margin: 5px 0;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #0000001a;
  }
}
@media screen and (min-width: 1025px) and(max-width: 1440px) {
  .Pc-back {
    width: 30px;
  }
  .Pc-select {
    margin-top: 4px;
  }
  .Pc-link-box {
    position: absolute;
    // left: 50%;
    bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .Pc-apple-link img {
    height: 65px;
    margin-right: 1rem;
  }
  .Pc-google-link img {
    height: 65px;
  }
  .nav-box {
    width: 100%;
    position: absolute;
    height: 44px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    width: 724px;
    display: block;
    margin: 0 auto;
  }
  .nav-language {
    position: absolute;
    right: 25%;
    top: 40px;
    margin: 5px 0;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #0000001a;
  }
}
@media screen and (min-width: 1441px) and(max-width: 2560px) {
  .Pc-link-box {
    position: absolute;
    bottom: 9.3rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .Pc-back {
    width: 30px;
  }
  .Pc-apple-link img {
    height: 65px;
    margin-right: 13rem;
  }
  .Pc-google-link img {
    height: 65px;
  }
  .nav-box {
    width: 100%;
    position: absolute;
    height: 44px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  .nav {
    width: 1000px;
    height: 44px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-language {
    position: absolute;
    // right: 31%;
    right: 24%;
    top: 40px;
    margin: 5px 0;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #0000001a;
  }
}
.Pc-link-box {
  position: absolute;
  bottom: 6.3rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.Pc-apple-link img {
  height: 65px;
  margin-right: 1rem;
}
.Pc-google-link img {
  height: 65px;
}
.Pc-wholeImg {
  width: 100%;
  height: 100%;
  margin-top: 15px;
}
</style>